import Badge from '../../../components/Badge';

interface IBadgesProps {
  badges: string[];
  className?: string;
}

const Badges = ({ badges, className }: IBadgesProps) => {
  return (
    <div className={`flex flex-wrap ${className}`}>
      {
        badges.map(text => <Badge key={text} text={text} />)
      }
    </div>
  );
};

export default Badges;