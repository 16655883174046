export const heroAnimation = {
  initial: { opacity: 0, x: '-100vw' },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5 }
};

export const linksAnimation = {
  initial: { opacity: 0, x: '100vw' },
  animate: { opacity: 1, x: 0 },
  transition: { duration: 0.5 }
};

export const detailsAnimation = {
  initial:{ opacity: 0, y: 100 },
  animate:{ opacity: 1, y: 0 },
  transition:{ duration: 0.5, delay: 0.3 }
};