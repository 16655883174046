import Button from '../../../components/Button';

interface IPagination {
  paginate: (direction: number) => void;
  currentPage: number;
  totalPages: number;
  elPerPage: number;
};

const Pagination = ({ paginate, currentPage, totalPages, elPerPage }: IPagination) => {
  return (
    <div className='flex justify-center mt-5'>
      <Button onClick={() => paginate(-1)} isActive={currentPage > 1} className='w-auto mr-8'>Prev</Button>
      <Button onClick={() => paginate(1)} isActive={totalPages > currentPage * elPerPage} className='w-auto'>Next</Button>
    </div>
  );
};

export default Pagination;