interface IBadge {
  text: string;
}

const Badge = ({ text }: IBadge) => {
  return (
    <span className='flex justify-center items-center max-w-min text-xs font-bold text-neutral-500 bg-neutral-200 py-1 px-3 rounded-md cursor-default mr-2 mt-2'>
      <p className='pt-1'>{text}</p>
    </span>
  );
};

export default Badge;