export const sendEmailReq = async (email: string, subject: string, message: string) => {
  try {
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/sendEmail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        subject,
        message,
      }),
    });

    const data = await resp.json();

    if (!resp.ok) {
      throw new Error(data.msg);
    }
    return {
      msg: data.msg,
      error: false,
    };
  }
  catch (e) {
    return {
      msg: e,
      error: true,
    };
  }
};