import { useReducer } from 'react';

import SideSocialNetworks from './components/SideSocialNetworks';
import AppRouter from './routers/AppRouter';
import AppContext from './contexts/App/AppContext';
import appReducer, { appReducerInitialState } from './contexts/App/AppReducer';

const App = () => {

  const [app, dispatch] = useReducer(appReducer, appReducerInitialState);

  return (
    <div className='overflow-x-hidden'>
      <SideSocialNetworks />
      <AppContext.Provider value={{ app, dispatch }}>
        <AppRouter />
      </AppContext.Provider>
    </div>
  );
}

export default App;
