import { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Filters from '../../common/Filters';
import Section from "../../../components/Section";
import Pagination from '../../common/Pagination';
import ProjectsCarousel from '../../common/ProjectsCarousel';
import SectionTitle from '../../../components/SectionTitle';
import useFilterProjects from '../../../hooks/useFilterProjects';
import useCheckDeviceScreen from '../../../hooks/useCheckDeviceScreen';
import { sectionAnimationInView, sectionAnimationNotInView } from './animations';

import ProjectsData from '../../../assets/data/projects.json';

const Projects = () => {

  const { ref, inView } = useInView();
  const animation = useAnimation();

  const [projectsData, setProjectsData] = useState(ProjectsData["en"]);
  const device = useCheckDeviceScreen();
  const { filteredProjects, setPage, totalPages, setCategory, selectedCategory, currentPage } = useFilterProjects(projectsData, (device === 'large desktop' ? 3 : 2));
  const [direction, setDirection] = useState(-1);

  const paginate = (newDirection: number) => {
    setDirection(newDirection);
    setPage(currentPage + 1 * newDirection, selectedCategory);
  }

  useEffect(() => {
    if(inView) {
      animation.start(sectionAnimationInView);
    }
  }, [inView, animation]);

  return (
    <Section id='projects' color='secondary' ref={ref}>
      <motion.div className='w-full m-0 p-0 z-20' animate={animation} initial={sectionAnimationNotInView}>
        <SectionTitle className='z-30'>Projects</SectionTitle>
        <Filters
          selectedCategory={selectedCategory}
          setCategory={setCategory}
          className='z-20'
        />

        <ProjectsCarousel
          currentPage={currentPage}
          direction={direction}
          filteredProjects={filteredProjects}
          paginate={paginate}
        />

        <Pagination
          paginate={paginate}
          currentPage={currentPage}
          totalPages={totalPages}
          elPerPage={device === 'large desktop' ? 3 : 2}
        />
      </motion.div>
    </Section>
  );
};

export default Projects;