import { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Project from '../Project';
import { IProject } from '../../../interfaces/projectInterface';

interface IProjectsCarousel {
  direction: number;
  currentPage: number;
  filteredProjects: IProject[];
  paginate: (page: number) => void;
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

const ProjectsCarousel = ({ currentPage, direction, filteredProjects, paginate }: IProjectsCarousel) => {

  const swipeConfidenceThreshold = useRef(15000);

  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <AnimatePresence custom={direction} exitBeforeEnter initial={false}>
      <motion.div
        key={currentPage.toString()}
        initial="enter"
        custom={direction}
        variants={variants}
        animate="center"
        exit="exit"
        transition={{
          x: { type: "spring", stiffness: 300, damping: 30 },
          opacity: { duration: 0.2 }
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold.current) {
            paginate(1);
          } else if (swipe > swipeConfidenceThreshold.current) {
            paginate(-1);
          }
        }}
        className='lg:flex lg:justify-center'
      >
        {
          filteredProjects.map((data: IProject) => (
            <Project
              key={data.id}
              id={data.id}
              title={data.title}
              description={data.description}
              badges={data.badges}
              image_url={data.image_url}
              technologies={data.technologies}
              project_color={data.project_color}
              links={data.links}
              className='mt-4 lg:mx-2'
            />
          ))
        }
      </motion.div>
    </AnimatePresence>
  );
};

export default ProjectsCarousel;