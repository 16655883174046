import Section from '../../../components/Section';
import ContentTitle from '../../../components/ContentTitle';

interface IDescriptionProps {
  text: string;
};

const Description = ({ text }: IDescriptionProps) => {
  return (
    <Section className='bg-transparent pb-0 md:pb-0'>
      <ContentTitle>Description</ContentTitle>
      <p className='self-start text-sm text-neutral-300'>{text}</p>
    </Section>
  );
};

export default Description;