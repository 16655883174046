import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface IInput extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
}

const Input = ({ ...rest }: IInput) => {

  const { className } = rest;

  return (
    <input {...rest} className={`w-full py-1 px-2 bg-primary-300 text-neutral-200 border-2 border-primary-desaturated-300 focus:border-primary-100 focus:outline-none ${className}`} />
  );
};

export default Input;