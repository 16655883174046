export const sectionAnimationInView = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.3,
  },
};

export const sectionAnimationNotInView = {
  opacity: 0,
  y: 100,
};