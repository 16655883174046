import { createElement } from 'react';
import { ReactComponent as GithubIcon } from '../../../assets/images/github.svg';
import { ReactComponent as GlobeIcon } from '../../../assets/images/globe.svg';

type linkType = 'github' | 'website';

interface LinksProps {
  name: string;
  link: string;
  type: linkType | string;
  className?: string;
};

interface ILink {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  alt: string;
};

const iconMap: { [x: string]: ILink } = {
  github: {
    icon: GithubIcon,
    alt: 'Github',
  },
  website: {
    icon: GlobeIcon,
    alt: 'Website',
  },
};

const ProjectLink = ({ name, link, type, className }: LinksProps) => {
  return (
    <div className={`group ${className}`}>
      <a className='flex flex-col items-center group-hover:fill-current group-hover:text-primary-100 transform group-hover:scale-110 transition-all ease-linear' href={link} target='_blank' rel='noreferrer'>
        {
          createElement(iconMap[type].icon, { className: 'stroke-current group-hover:text-primary-100'})
        }
        <p className='text-xs mt-2'>{name}</p>
      </a>
    </div>
  );
};

export default ProjectLink;