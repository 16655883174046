import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Section from '../../../components/Section';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import SectionTitle from '../../../components/SectionTitle';
import SocialMedia from '../../../components/SocialMedia';
import { sendEmailReq } from '../../../api/contact';
import { formAnimationInView, formAnimationNotInView, socialsAnimationInView, socialsAnimationNotInView } from './animations';

const Contact = () => {

  const [contactData, setContactData] = useState({
    email: '',
    subject: '',
    message: ''
  });

  const { ref, inView } = useInView();
  const formAnimation = useAnimation();
  const socialsAnimation = useAnimation();

  const { email, subject, message } = contactData;

  const formHandler = (e: FormEvent) => {
    e.preventDefault();
  };

  const inputHandler = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.currentTarget;

    setContactData({ ...contactData, [name]: value });
  };

  const sendEmail = async () => {
    let errorMessage = '';

    if (email.trim().length === 0 || subject.trim().length === 0 || message.trim().length === 0) {
      errorMessage = 'You should not leave any input empty';
    }

    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailRegex.test(email)) {
      errorMessage = 'Email is not correct';
    }

    if (errorMessage.length > 0) {
      Swal.fire({
        title: errorMessage,
        icon: 'error',
        background: '#051529',
        customClass: {
          title: 'swal2-title',
        },
      });

      return;
    }

    Swal.fire({
      title: 'Sending...',
      background: '#051529',
      customClass: {
        title: 'swal2-title',
      },
    });
    Swal.showLoading();

    const res = await sendEmailReq(email, subject, message);

    if (res.error) {
      Swal.close();
      Swal.fire({
        title: res.msg,
        icon: 'error',
        background: '#051529',
        customClass: {
          title: 'swal2-title',
        },
      });
      return;
    };

    clear();
    Swal.close();
    Swal.fire({
      title: res.msg,
      icon: 'success',
      background: '#051529',
      customClass: {
        title: 'swal2-title',
        confirmButton: 'swal2-confirm'
      },
    });
  };

  const clear = () => {
    setContactData({
      email: '',
      subject: '',
      message: ''
    });
  };

  useEffect(() => {
    if (inView) {
      formAnimation.start(formAnimationInView);
      socialsAnimation.start(socialsAnimationInView);
    }
  }, [inView, formAnimation, socialsAnimation]);

  return (
    <Section id='contact' color='secondary'>
      <div className='w-full md:flex' ref={ref}>
        <motion.div className='w-full md:w-8/12 md:self-start 2xl:w-6/12' animate={formAnimation} initial={formAnimationNotInView}>
          <SectionTitle>Contact</SectionTitle>
          <form onSubmit={formHandler} className='flex flex-col items-center'>
            <Input
              type='email'
              placeholder='Email'
              className='my-1'
              value={email}
              onChange={inputHandler}
              name='email'
            />
            <Input
              placeholder='Subject'
              className='my-1'
              value={subject}
              onChange={inputHandler}
              name='subject'
            />
            <textarea
              className='w-full py-1 px-2 my-1 resize-none bg-primary-300 border-2 border-primary-desaturated-300 text-neutral-100 focus:border-primary-100 focus:outline-none'
              placeholder='Leave your message here :D'
              rows={4}
              value={message}
              onChange={inputHandler}
              name='message'
            />
            <Button
              onClick={sendEmail}
              type='button'
              className='w-full mt-3'
            >
              Send
            </Button>
          </form>
        </motion.div>
        <motion.div className='md:w-4/12 md:mt-12 md:ml-5 xl:ml-20 2xl:w-5/12' animate={socialsAnimation} initial={socialsAnimationNotInView}>
          <p className='text-primary-desaturated-300 mt-6'>Want to contact me via socials? You can!</p>
          <div className='flex self-start mt-4'>
            <SocialMedia socialMediaId='twitter' className='mr-6' />
            <SocialMedia socialMediaId='github' />
          </div>
        </motion.div>
      </div>
    </Section>
  );
};

export default Contact;