import { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';

import AppContext from '../../../contexts/App/AppContext';
import { IProject } from '../../../interfaces/projectInterface';
import Badges from '../Badges';

interface IProjectProps extends IProject {
  className?: string;
}

const Project = ({ id, title, description, badges, technologies, image_url, project_color, links, className = '' }: IProjectProps) => {

  const { dispatch } = useContext(AppContext);

  const projectHandler = () => {
    dispatch(
      {
        type: '[App] Set BG Color',
        payload: 'bg-project-red',
      }
    );
  };

  return (
    <HashLink
    onClick={projectHandler}
    className={`block py-3 px-4 bg-neutral-100 text-black font-semibold rounded-md md:flex ${(id % 2 !== 0) && 'md:flex-row-reverse'} md:w-full lg:flex-col lg:max-w-max lg:pt-0 lg:pb-7 lg:px-7 lg:transform lg:hover:scale-102 lg:transition-transform lg:ease-linear ${className}`}
      to={
        {
          pathname: `/project/${id}`,
          state: { id, title, description, image_url, technologies, project_color, links }
        }
      }
    >
      <div className='md:mt-8'>
        <p className='text-lg line-clamp-1 md:w-52 md:line-clamp-4 lg:w-full lg:line-clamp-1'>{title}</p>
        <Badges badges={badges} />
      </div>
      <div className='inline-block mt-5 mx-auto md:w-96 lg:w-auto'>
        <img
          className='md:w-96 2xl:w-100'
          src={`${process.env.REACT_APP_URL}/${image_url}`}
          alt={title}
        />
      </div>
    </HashLink>
  );
};

export default Project;