import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from '../widgets/common/Header';
import HomePage from '../pages/Home';
import ProjectPage from '../pages/Project';

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
      <Header />
        <div className='pt-24'>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/project/:id' component={ProjectPage} />

            <Route exact path='*' component={HomePage} />
          </Switch>
        </div>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;