interface ISectionTitleProps {
  children: string;
  className?: string;
};

const SectionTitle = ({ children, className='' }: ISectionTitleProps) => {
  return (
    <h2 className={`text-2xl self-start font-semibold mb-4 md:mb-5 ${className}`}>
      {children}
    </h2>
  );
};

export default SectionTitle;