import { forwardRef } from "react";

interface ISection {
  children: JSX.Element | JSX.Element[];
  id?: string;
  color?: 'primary' | 'secondary';
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
}

const bgMap = {
  primary: 'bg-primary-400',
  secondary: 'bg-primary-300',
};

const Section = forwardRef<HTMLDivElement, ISection>(({ id, color = 'primary', className = '', children }: ISection, ref) => {
  return (
    <section ref={ref} id={id} className={`flex flex-col items-center px-8 py-5 sm:px-24 md:py-10 xl:px-52 2xl:px-64 ${bgMap[color]} ${className}`}>
      {children}
    </section>
  );
});

export default Section;