import { useEffect } from 'react';

interface IScrollToTop {
  children: JSX.Element | JSX.Element[];
};

const ScrollToTop = ({ children }: IScrollToTop) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {
        children
      }
    </>
  );
};

export default ScrollToTop;