import { IProjectLink } from '../../../interfaces/projectInterface';

import Section from "../../../components/Section";
import ProjectLink from "../../common/ProjectLink";

interface IProjectLinksProps {
  links: IProjectLink[];
};

const ProjectLinks = ({ links }: IProjectLinksProps) => {
  return (
    <Section className='bg-transparent pb-0 md:pb-0 md:pt-8'>
      <div className='flex flex-wrap'>
        {
          links.map((link: IProjectLink) => (
            <ProjectLink
              key={link.name}
              name={link.name}
              link={link.url}
              type={link.type}
              className='m-2 md:mx-3 lg:mx-3.5'
            />
          ))
        }
      </div>
    </Section>
  );
};

export default ProjectLinks;