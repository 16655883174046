export const formAnimationInView = {
  opacity: 1,
  x: 0,
  transition: {
    duration: 0.3,
  },
};

export const formAnimationNotInView = {
  opacity: 0,
  x: '-100vw',
};

export const socialsAnimationInView = {
  opacity: 1,
  x: 0,
  transition: {
    duration: 0.3,
  },
};

export const socialsAnimationNotInView = {
  opacity: 0,
  x: '100vw',
};