import PythonLogo from '../../assets/images/python-logo.svg';
import JavascriptLogo from '../../assets/images/javascript-logo.svg';
import TypescriotLogo from '../../assets/images/typescriptlang-logo.svg';
import TensorflowLogo from '../../assets/images/tensorflow-logo.svg';
import ReactLogo from '../../assets/images/react-logo.svg';
import NodeLogo from '../../assets/images/nodejs-logo.svg';
import FlaskLogo from '../../assets/images/flask-logo.svg';
import PostgresLogo from '../../assets/images/postgresql-logo.svg';
import MySQLLogo from '../../assets/images/mysql-logo.svg';
import SQLServerLogo from '../../assets/images/microsoft-sql-server-logo.svg';
import MongoDBLogo from '../../assets/images/mongodb-logo.svg';
import DockerLogo from '../../assets/images/docker-logo.svg';
import TailwindLogo from '../../assets/images/tailwindcss-logo.svg';
import StyledComponentsLogo from '../../assets/images/styled-components-logo.png';

export type skillId = 'python' | 'javascript' | 'typescript' | 'tensorflow' | 'react' | 'reactnative' | 'node' | 'flask' | 'postgresql' | 'mysql' | 'sqlserver' | 'mongodb' | 'docker' | 'tailwind' | 'styledcomponents';

interface ISkillProps {
  skillsId: skillId[] | string[];
  title?: string;
  className?: string;
}

interface ISkill {
  name: string;
  logo: string;
};

const skillsMap: {[x: string]: ISkill} = {
  python: {
    name: 'Python',
    logo: PythonLogo,
  },
  javascript: {
    name: 'Javascript',
    logo: JavascriptLogo,
  },
  typescript: {
    name: 'Typescript',
    logo: TypescriotLogo,
  },
  tensorflow: {
    name: 'Tensorflow',
    logo: TensorflowLogo,
  },
  react: {
    name: 'React',
    logo: ReactLogo,
  },
  reactnative: {
    name: 'React Native',
    logo: ReactLogo,
  },
  node: {
    name: 'NodeJS',
    logo: NodeLogo,
  },
  flask: {
    name: 'Flask',
    logo: FlaskLogo,
  },
  postgresql: {
    name: 'PostgreSQL',
    logo: PostgresLogo,
  },
  mysql: {
    name: 'MySQL',
    logo: MySQLLogo,
  },
  sqlserver: {
    name: 'SQL Server',
    logo: SQLServerLogo,
  },
  mongodb: {
    name: 'MongoDB',
    logo: MongoDBLogo,
  },
  docker: {
    name: 'Docker',
    logo: DockerLogo,
  },
  tailwind: {
    name: 'TailwindCSS',
    logo: TailwindLogo,
  },
  styledcomponents: {
    name: 'Styled Components',
    logo: StyledComponentsLogo,
  },
};

const Skillset = ({ title, skillsId, className }: ISkillProps) => {
  return (
    <div className={`w-full z-10 ${className}`}>
      {
        title && <p className='self-start mb-3'>{title}</p>
      }
      <div className='flex items-center self-start flex-wrap 2xl:justify-start'>
        {
          skillsId?.map((skill: string) => (
            <div
              key={skillsMap[skill].name}
              className='flex flex-col items-center w-28 h-28 py-2 lg:mr-2 2xl:mr-0'>
              <img
                className='h-10'
                src={skillsMap[skill].logo}
                alt={skillsMap[skill].name}
              />
              <div className='flex items-center h-full'>
                <p className='text-sm text-primary-desaturated-400 text-center pt-1'>{skillsMap[skill].name}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default Skillset;