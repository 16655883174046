import { Transition } from "framer-motion";

export const textAnimation = {
  initial: {
    x: '-100vw',
  },
  animate: {
    x: 0,
  },
  transition: {
    duration: 0.5,
    type: 'spring',
    bounce: 0.2
  },
};

export const blobEnterAnimation = {
  initial: {
    x: '100vw',
  },
  animate: {
    x: 0,
  },
  transition: {
    duration: 0.5,
    type: 'spring',
    bounce: 0.2,
    delay: 0.3,
  },
};

export const arrowAnimation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 0.5,
    delay: 0.5,
  },
};

const firstBlob =
  "M175.3 -186.7C213 -137.7 219.5 -68.8 203.9 -15.6C188.4 37.7 150.8 75.4 113.1 103.8C75.4 132.1 37.7 151 1.2 149.9C-35.4 148.7 -70.7 127.4 -116.4 99C-162 70.7 -218 35.4 -235.7 -17.7C-253.4 -70.7 -232.8 -141.4 -187.1 -190.4C-141.4 -239.4 -70.7 -266.7 -0.9 -265.8C68.8 -264.8 137.7 -235.7 175.3 -186.7"

const secondBlob =
  "M118 -127.7C151.7 -84.4 176.9 -42.2 187.2 10.4C197.6 62.9 193.2 125.9 159.5 150.9C125.9 175.9 62.9 162.9 -3.9 166.8C-70.7 170.7 -141.4 191.4 -187.6 166.4C-233.8 141.4 -255.4 70.7 -249.5 5.9C-243.6 -58.9 -210.2 -117.9 -164 -161.2C-117.9 -204.5 -58.9 -232.3 -8.4 -223.9C42.2 -215.5 84.4 -171 118 -127.7"

export const blobAnimation = {
  initial: { d: firstBlob },
  animate: { d: secondBlob, rotateZ: 25, translateY: '-50px', translateX: '40px' },
  transition: {
    duration: 4,
    repeat: Infinity,
    repeatType: 'mirror',
    ease: 'easeInOut',
  } as Transition,
};