import { useCallback, useEffect, useState } from 'react';

import { getProjectsByCategory } from '../helpers/projects';
import { IProject } from '../interfaces/projectInterface';

const useFilterProjects = (projects: IProject[], elPerPage: number = 2) => {
  const [filteredProjects, setFilteredProjects] = useState(projects);
  const [totalPages, setTotalPages] = useState(projects.length);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);

  const setPage = useCallback((page: number, category: string) => {
    let newProjects = [];

    if (category !== 'all') {
      newProjects = getProjectsByCategory(projects, category);
    }
    else {
      newProjects = projects;
    }

    const newFilteredProjects = newProjects.slice((page - 1) * elPerPage, page * elPerPage);

    if ((newFilteredProjects.length > page * 2 || newFilteredProjects.length === 0) && page !== 1) return;

    setFilteredProjects(newFilteredProjects);
    setTotalPages(newProjects.length);
    setCurrentPage(page);
  }, [elPerPage, projects]);

  const setCategory = (category: string) => {
    setSelectedCategory(category);
    setPage(1, category);
  }

  useEffect(() => {
    setPage(1, 'all');
  }, [elPerPage, setPage]);


  return {
    filteredProjects,
    setPage,
    setCategory,
    totalPages,
    selectedCategory,
    currentPage,
  }
};

export default useFilterProjects;