import { createElement } from 'react';
import { ReactComponent as GithubIcon } from '../../assets/images/github.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter.svg';

interface ISocialMediaProps {
  socialMediaId: 'github' | 'twitter';
  className?: string;
};

const socialMediaMap = {
  github: {
    icon: GithubIcon,
    link: 'https://github.com/carb2750',
  },
  twitter: {
    icon: TwitterIcon,
    link: 'https://twitter.com/christianrapalo',
  },
};

const SocialMedia = ({ socialMediaId, className = '' }: ISocialMediaProps) => {
  return (
    <a href={socialMediaMap[socialMediaId].link} target='_blank' rel="noreferrer" className={`text-neutral-100 group group-hover:fill-current group-hover:text-primary-100 transform hover:scale-110 transition-all ease-linear ${className}`}>
      {
        createElement(socialMediaMap[socialMediaId].icon, { className: 'stroke-current group-hover:text-primary-100'})
      }
    </a>
  );
};

export default SocialMedia;