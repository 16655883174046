import SocialMedia from '../SocialMedia';

const SideSocialNetworks = () => {
  return (
    <div className='hidden md:fixed md:left-6 md:bottom-0 md:flex md:flex-col md:items-center md:z-50 lg:left-9 xl:left-20 2xl:left-24'>
      <SocialMedia socialMediaId='github' className='mb-7' />
      <SocialMedia socialMediaId='twitter' className='mb-7' />
      <div className='w-0.5 h-36 bg-neutral-100'></div>
    </div>
  );
};

export default SideSocialNetworks;