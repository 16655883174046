import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

interface IButton extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: string;
  onClick: () => void;
  className?: string;
  isActive?: boolean;
}

const Button = ({ onClick, isActive = true, className = '', children, ...rest }: IButton) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 pb-1 pt-1.5 bg-primary-200 rounded-sm ${className} ${!isActive ? 'text-neutral-400 bg-neutral-300 cursor-default' : 'hover:bg-primary-desaturated-400'}`}
      disabled={!isActive}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;