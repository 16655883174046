interface IContentProps {
  children: JSX.Element | JSX.Element[];
}

const Content = ({ children }: IContentProps) => {
  return (
    <section className='md:px-14 lg:px-28 xl:px-40 2xl:px-60'>
      {children}
    </section>
  );
};

export default Content;