interface IContentTitleProps {
  children: string;
};

const ContentTitle = ({ children }: IContentTitleProps) => {
  return (
    <h2 className='self-start text-lg font-semibold mb-3'>{children}</h2>
  );
};

export default ContentTitle;