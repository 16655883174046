import { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { motion } from 'framer-motion';

import ScrollToTop from '../../components/ScrollToTop';
import SectionTitle from '../../components/SectionTitle';
import AppContext from '../../contexts/App/AppContext';
import { IProject, IProjectLink } from '../../interfaces/projectInterface';
import BuiltWith from '../../widgets/Project/BuiltWith';
import Content from '../../components/Content';
import Description from '../../widgets/Project/Description';
import { heroAnimation, detailsAnimation, linksAnimation } from './animations';

import ProjectsData from '../../assets/data/projects.json';
import ProjectLinks from '../../widgets/Project/ProjectLinks';

interface ProjectProps extends IProject {
};

const backgroundMap: { [x: string]: string } = {
  'bg-project-red': 'from-project-red',
  'bg-project-blue': 'from-project-blue',
};

const Project = ({ location, match }: RouteComponentProps<{}, any, ProjectProps>) => {

  const { dispatch } = useContext(AppContext);
  const { id }: any = match.params;
  const [project, setProject] = useState({
    title: '',
    image_url: '',
    description: '',
    technologies: [] as string[],
    project_color: '',
    links: [] as IProjectLink[],
  });
  const [loading, setLoading] = useState(true);

  const { title, image_url, description, technologies, project_color, links } = project;

  const getProjectById = useCallback(() => {
    const currentProject = ProjectsData["en"].find(project => project.id === Number(id));
    setProject({
      ...project,
      ...currentProject
    });
  }, [id, project]);

  useEffect(() => {
    if (!title) {
      if (location.state) {
        setProject({
          ...project,
          ...location.state,
          technologies: [...technologies, ...location.state.technologies],
          links: location.state.links ? [...links, ...location.state.links] : [],
        });
      }
      else {
        getProjectById();
      }
    }
    else {
      setLoading(false);
    }

    dispatch({
      type: '[App] Set BG Color',
      payload: project_color,
    });
  }, [project_color, dispatch, getProjectById, location.state, title, technologies, project, links]);

  if (loading) {
    return (
      <div className={`flex justify-center w-screen min-h-screen-90 pt-5 md:min-h-screen bg-gradient-to-b ${backgroundMap[project_color]} to-primary-400 md:pt-10 2xl:pt-20`}>
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-neutral-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    );
  }

  return (
    <ScrollToTop>
      <div className={`w-screen min-h-screen-90 text-white md:min-h-screen bg-gradient-to-b overflow-x-hidden ${backgroundMap[project_color]} to-primary-400`}>
        <motion.div {...heroAnimation}>
          <SectionTitle className='text-center pt-5 md:text-3xl md:pt-10 lg:text-4xl 2xl:pt-16'>{title}</SectionTitle>
          <motion.img
            className='w-5/6 mx-auto xl:w-3/5 2xl:w-7/12'
            src={`${process.env.REACT_APP_URL}/${image_url}`}
            alt={title}
          />
        </motion.div>
        <motion.div {...linksAnimation}>
          <ProjectLinks links={links} />
        </motion.div>
        <Content>
          <motion.div {...detailsAnimation}>
            <Description text={description} />
            <BuiltWith technologies={technologies} />
          </motion.div>
        </Content>

      </div>
    </ScrollToTop>
  );
};

export default Project;