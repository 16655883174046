import { useContext } from 'react';
import { HashLink } from 'react-router-hash-link';

import SocialMedia from '../../../components/SocialMedia';
import AppContext from '../../../contexts/App/AppContext';

interface INavbar {
  isOpen: boolean;
}

const Navbar = ({ isOpen }: INavbar) => {

  const { app } = useContext(AppContext);
  const { bgColor } = app;

  return (
    <div className={`absolute left-1/2 transform -translate-x-1/2 top-24 flex flex-col items-center w-11/12 rounded-md text-neutral-100 px-6 py-5 z-50 md:relative md:flex md:flex-row md:justify-end md:w-full md:py-0 md:left-0 md:top-0 md:transform-none md:bg-opacity-0 md:text-neutral-gray ${!isOpen && 'hidden'} ${bgColor}`}>
      <div className='flex flex-col items-center md:flex-row'>
        <HashLink smooth to='/#projects' className='my-2 cursor-pointer font-bold hover:text-primary-100 md:ml-20'>Projects</HashLink>
        <HashLink smooth to='/#skills' className='my-2 cursor-pointer font-bold hover:text-primary-100 md:ml-20'>Skills</HashLink>
        <HashLink smooth to='/#contact' className='my-2 cursor-pointer font-bold hover:text-primary-100 md:ml-20'>Contact</HashLink>
      </div>
      <hr className='w-full border-neutral-gray opacity-30 my-3 md:hidden' />
      <div className='flex flex-col items-center md:flex-row md:hidden'>
        <div className='flex my-2'>
          <SocialMedia socialMediaId='github' className='mr-6' />
          <SocialMedia socialMediaId='twitter' />
        </div>
      </div>
    </div>
  );
};

export default Navbar;