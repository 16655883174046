export type AppReducerActionType =
  | { type: '[App] Set BG Color', payload: string }

export interface IAppReducerState {
  bgColor: string;
};

export const appReducerInitialState = {
  bgColor: 'bg-primary-400',
};

const appReducer = (state: IAppReducerState = appReducerInitialState, action: AppReducerActionType) => {
  switch (action.type) {
    case '[App] Set BG Color':
      return {
        bgColor: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;