export const sectionAnimationInView = {
  x: 0,
  transition: {
    duration: 0.5,
    type: 'spring',
    bounce: 0.2
  }
};

export const sectionAnimationNotInView = {
  x: '-100vw',
};