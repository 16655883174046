import { HashLink } from 'react-router-hash-link';
import { motion } from 'framer-motion';

import Section from '../../../components/Section';
import ArrowDownIcon from '../../../assets/images/arrow-down-circle.svg';
import PhotoBlob from '../../../assets/images/photo-blob.svg';
import useCheckDeviceScreen from '../../../hooks/useCheckDeviceScreen';
import { blobEnterAnimation, textAnimation, arrowAnimation, blobAnimation } from './animations';

const Hero = () => {

  const device = useCheckDeviceScreen();

  return (
    <Section
      className='relative min-h-screen-90 pt-6 md:pt-2 lg:min-h-screen xl:pt-20 2xl:pt-40'
    >
      <div className='hidden md:absolute md:right-0 md:bottom-20 md:inline-block md:w-1/6 md:h-40 md:bg-hexagons md:opacity-30 md:z-0 2xl:bottom-44'></div>
      <div className='hidden md:absolute md:left-0 md:top-0 md:inline-block md:w-2/6 md:h-24 md:bg-bank-note md:opacity-40 md:z-0 xl:w-3/12 2xl:w-2/12 2xl:top-10'></div>
      <div className='z-10 lg:flex lg:items-center'>
        <motion.div
          className='md:flex md:flex-col md:justify-center md:mt-10 md:px-16 md:text-center lg:w-7/12 lg:mr-5 lg:text-left 2xl:px-32'
          {...textAnimation}
        >
          <p className='text-3xl font-bold md:text-5xl'>Hi! I'm Christian,</p>
          <p className='text-primary-100 text-lg font-bold md:text-2xl md:mt-2'>Computer Science Engineer <span className='text-white font-semibold'>and</span> Machine Learning Enthusiast</p>
          <p className='text-sm text-primary-desaturated-400 mt-3 md:text-base'>My name is Christian Rápalo, I'm a computer science engineer who likes to create and improve every day.</p>
        </motion.div>

        {/* Blob */}
        <motion.div className='relative flex justify-center items-center h-64 mt-1 z-20 sm:mt-4 md:mt-8' {...blobEnterAnimation}>
          <svg className='absolute transform rotate-45 translate-y-5 w-120 fill-current text-primary-100 sm:w-144 md:w-160 2xl:w-188 2xl:translate-y-2' id="visual" viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <g transform="translate(465.1057210230548 357.9379855730472)">
              <motion.path {...blobAnimation}>
              </motion.path>
            </g>
          </svg>
          <div className='w-60 md:w-80 mt-14 z-10 2xl:w-96'>
            <img
              src={PhotoBlob}
              alt='Me'
            />
          </div>
        </motion.div>

        <br className='mt-36' />
        <div className='absolute left-0 -bottom-0 w-screen md:-bottom-10 lg:-bottom-8 xl:-bottom-20 2xl:-bottom-24'>
          {/* Arrow Down */}
          <motion.div className='flex justify-center w-full h-8 mb-2 z-10 md:h-10' {...arrowAnimation}>
            <HashLink smooth to='/#projects' className='w-auto h-full'>
              <img
                className='w-full h-full animate-bounce'
                src={ArrowDownIcon}
                alt='Arrow Down'
              />
            </HashLink>
          </motion.div>

          {
            device === 'mobile' || device === 'tablet'
              ?
              <svg width="100%" height="100%" viewBox="0 0 900 240" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M0,32l21.5,5.5c21.5,5.5 64.5,16.5 107.3,18.7c42.9,2.1 85.5,-4.5 128.4,-8c42.8,-3.5 85.8,-3.9 128.6,-9.9c42.9,-6 85.5,-17.6 128.4,-15.3c42.8,2.3 85.8,18.7 128.6,30.2c42.9,11.5 85.5,18.1 128.4,8.5c42.8,-9.7 85.8,-35.7 107.3,-48.7l21.5,-13l0,240l-900,0l0,-208Z" fill='#035388' />
                <path d="M0,91l21.5,-7.3c21.5,-7.4 64.5,-22 107.3,-26.2c42.9,-4.2 85.5,2.2 128.4,13.8c42.8,11.7 85.8,28.7 128.6,31.4c42.9,2.6 85.5,-9 128.4,-16.9c42.8,-7.8 85.8,-11.8 128.6,-12.5c42.9,-0.6 85.5,2 128.4,10c42.8,8 85.8,21.4 107.3,28l21.5,6.7l0,122l-900,0l0,-149Z" fill='#06426f' />
                <path d="M0,105l21.5,6.5c21.5,6.5 64.5,19.5 107.3,26c42.9,6.5 85.5,6.5 128.4,-4.3c42.8,-10.9 85.8,-32.5 128.6,-36.7c42.9,-4.2 85.5,9.2 128.4,18.8c42.8,9.7 85.8,15.7 128.6,8.7c42.9,-7 85.5,-27 128.4,-26c42.8,1 85.8,23 107.3,34l21.5,11l0,97l-900,0l0,-135Z" fill='#053156' />
                <path d="M0,138l21.5,5.7c21.5,5.6 64.5,17 107.3,24c42.9,7 85.5,9.6 128.4,5.6c42.8,-4 85.8,-14.6 128.6,-23.3c42.9,-8.7 85.5,-15.3 128.4,-14.7c42.8,0.7 85.8,8.7 128.6,12.4c42.9,3.6 85.5,3 128.4,4.8c42.8,1.8 85.8,6.2 107.3,8.3l21.5,2.2l0,77l-900,0l0,-102Z" fill='#03213e' />
                <path d="M0,180l21.5,-2.7c21.5,-2.6 64.5,-8 107.3,-10.1c42.9,-2.2 85.5,-1.2 128.4,4c42.8,5.1 85.8,14.5 128.6,19.3c42.9,4.8 85.5,5.2 128.4,1.2c42.8,-4 85.8,-12.4 128.6,-11.9c42.9,0.5 85.5,9.9 128.4,11c42.8,1.2 85.8,-5.8 107.3,-9.3l21.5,-3.5l0,62l-900,0l0,-60Z" fill='#001228' />
              </svg>
              :
              <svg width="100%" height="100%" viewBox="0 0 960 223" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M0,0l22.8,4c22.9,4 68.5,12 114.2,16.2c45.7,4.1 91.3,4.5 137,3c45.7,-1.5 91.3,-4.9 137.2,2.5c45.8,7.3 91.8,25.3 137.6,29c45.9,3.6 91.5,-7 137.2,-7.2c45.7,-0.2 91.3,10.2 137,10.5c45.7,0.3 91.3,-9.3 114.2,-14.2l22.8,-4.8l-0,184l-960,0l-0,-223Z" fill='#035388' /><path d="M0,92l22.8,-6.5c22.9,-6.5 68.5,-19.5 114.2,-27.7c45.7,-8.1 91.3,-11.5 137,-3.5c45.7,8 91.3,27.4 137.2,26.5c45.8,-0.8 91.8,-21.8 137.6,-24.6c45.9,-2.9 91.5,12.5 137.2,21.1c45.7,8.7 91.3,10.7 137,9.4c45.7,-1.4 91.3,-6 114.2,-8.4l22.8,-2.3l-0,147l-960,0l-0,-131Z" fill='#06426f' /><path d="M0,107l22.8,5.5c22.9,5.5 68.5,16.5 114.2,12c45.7,-4.5 91.3,-24.5 137,-30.8c45.7,-6.4 91.3,1 137.2,1.6c45.8,0.7 91.8,-5.3 137.6,-3.3c45.9,2 91.5,12 137.2,20.7c45.7,8.6 91.3,16 137,14c45.7,-2 91.3,-13.4 114.2,-19l22.8,-5.7l-0,121l-960,0l-0,-116Z" fill='#053156' /><path d="M0,149l22.8,-2c22.9,-2 68.5,-6 114.2,-10.2c45.7,-4.1 91.3,-8.5 137,-9.3c45.7,-0.8 91.3,1.8 137.2,3.8c45.8,2 91.8,3.4 137.6,4c45.9,0.7 91.5,0.7 137.2,-0.6c45.7,-1.4 91.3,-4 137,-3.7c45.7,0.3 91.3,3.7 114.2,5.3l22.8,1.7l-0,85l-960,-0l-0,-74Z" fill='#03213e' /><path d="M0,165l22.8,-1.2c22.9,-1.1 68.5,-3.5 114.2,0.4c45.7,3.8 91.3,13.8 137,15.5c45.7,1.6 91.3,-5 137.2,-4.7c45.8,0.3 91.8,7.7 137.6,10.5c45.9,2.8 91.5,1.2 137.2,-0c45.7,-1.2 91.3,-1.8 137,-0c45.7,1.8 91.3,6.2 114.2,8.3l22.8,2.2l-0,27l-960,-0l-0,-58Z" fill='#001228' /></svg>
          }
        </div>
      </div>
    </Section>
  );
};

export default Hero;