interface IFilterProps {
  text: string;
  filterHandler: () => void;
  isActive?: boolean;
}

const Filter = ({ text, filterHandler, isActive=false }: IFilterProps) => {
  return (
    <p className={`mx-1.5 cursor-pointer ${isActive && 'border-b-2 border-primary-100'}`} onClick={filterHandler}>{text}</p>
  );
};

export default Filter;