import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Section from '../../../components/Section';
import Skillset from '../../../components/Skillset/Skillset';
import SectionTitle from '../../../components/SectionTitle';
import { sectionAnimationInView, sectionAnimationNotInView } from './animations';

const Skills = () => {

  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start(sectionAnimationInView);
    }
  }, [inView, animation]);

  return (
    <Section id='skills' className='md:relative'>
      <div className='hidden md:absolute md:left-0 md:bottom-0 md:inline-block md:w-1/6 md:h-20 md:bg-line-in-motion md:opacity-30'></div>
      <div className='hidden md:absolute md:right-0 md:top-0 md:inline-block md:w-1/6 md:h-40 md:bg-squares md:opacity-40'></div>
      <motion.div ref={ref} animate={animation} initial={sectionAnimationNotInView}>
        <SectionTitle>Skills</SectionTitle>
        <div className='self-start md:flex md:flex-wrap md:w-full'>
          <Skillset
            title='Languages'
            skillsId={['python', 'javascript', 'typescript']}
          />
          <Skillset
            title='Technologies'
            skillsId={['tensorflow', 'react', 'reactnative', 'node', 'flask', 'docker', 'tailwind', 'styledcomponents']}
            className='mt-3 md:mt-0'
          />
          <Skillset
            title='Databases'
            skillsId={['postgresql', 'mysql', 'sqlserver', 'mongodb']}
            className='mt-3 md:mt-0'
          />
        </div>
      </motion.div>
    </Section>
  );
};

export default Skills;