import { useEffect, useState } from 'react';

type devices = 'mobile' | 'tablet' | 'laptop' | 'desktop' | 'large desktop';

const useCheckDeviceScreen = () => {
  const [device, setDevice] = useState<devices>('mobile');

  const handleWindowSizeChange = () => {
    const width = window.innerWidth;

    if(width >= 1536) {
      setDevice('large desktop');
    }
    else if (width > 1280) {
      setDevice('desktop');
    }
    else if (width > 1024) {
      setDevice('laptop');
    }
    else if (width > 768) {
      setDevice('tablet');
    }
    else {
      setDevice('mobile');
    }
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return device;
}

export default useCheckDeviceScreen;