import { useContext, useEffect } from 'react';

import Hero from '../../widgets/Home/Hero';
import Projects from '../../widgets/Home/Projects';
import Footer from '../../widgets/common/Footer';
import Skills from '../../widgets/Home/Skills';
import Contact from '../../widgets/Home/Contact';
import AppContext from '../../contexts/App/AppContext';

const Home = () => {

  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch(
      {
        type: '[App] Set BG Color',
        payload: 'bg-primary-400',
      }
    );
  }, [dispatch]);

  return (
    <div className='w-screen bg-primary-400 text-white'>
      <Hero />
      <Projects />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;