import Section from '../../../components/Section';
import Skillset from '../../../components/Skillset';
import ContentTitle from '../../../components/ContentTitle';

interface IBuiltWithProps {
  technologies: string[];
};

const BuiltWith = ({technologies}: IBuiltWithProps) => {
  return (
    <Section className='bg-transparent pb-0 md:pb-0 md:pt-8'>
      <ContentTitle>Built with</ContentTitle>
      <Skillset
        skillsId={technologies}
      />
    </Section>
  );
};

export default BuiltWith;