import { useContext, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

import Navbar from '../Navbar';
import useCheckDeviceScreen from '../../../hooks/useCheckDeviceScreen';
import AppContext from '../../../contexts/App/AppContext';

import LogoComplete from '../../../assets/images/Logo_Complete_White.png';
import Logo from '../../../assets/images/Logo.png';

const Header = () => {

  const { app } = useContext(AppContext);
  const { bgColor } = app;
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const device = useCheckDeviceScreen();

  return (
    <header className={`absolute flex justify-between items-center w-full text-neutral-100 p-6 lg:px-24 xl:px-52 2xl:px-64 ${bgColor}`}>
      <HashLink to='/' className='min-w-max cursor-pointer'>
        <img
          className='h-12'
          src={(device === 'laptop' || device === 'desktop' || device === 'large desktop') ? LogoComplete : Logo}
          alt='Logo'
        />
      </HashLink>

      {/* Hamburger */}
      <div className='flex flex-col justify-center w-5 h-6 cursor-pointer md:hidden' onClick={() => setIsNavbarOpen(prevState => !prevState)}>
        <span className='w-full h-0.5 my-0.5 bg-neutral-100'></span>
        <span className='w-full h-0.5 my-0.5 bg-neutral-100'></span>
        <span className='w-full h-0.5 my-0.5 bg-neutral-100'></span>
      </div>

      <Navbar isOpen={isNavbarOpen} />
    </header>
  );
};

export default Header;