import Filter from '../../../components/Filter';

interface IFiltersProps {
  setCategory: (category: string) => void;
  selectedCategory: string;
  className?: string;
};

const Filters = ({ selectedCategory, setCategory, className='' }: IFiltersProps) => {
  return (
    <div className={`flex text-sm md:self-start ${className}`}>
      <Filter
        text='All'
        filterHandler={() => setCategory('all')}
        isActive={selectedCategory === 'all'}
      />
      <Filter
        text='Web'
        filterHandler={() => setCategory('web')}
        isActive={selectedCategory === 'web'}
      />
      <Filter
        text='Mobile'
        filterHandler={() => setCategory('mobile')}
        isActive={selectedCategory === 'mobile'}
      />
      <Filter
        text='Machine Learning'
        filterHandler={() => setCategory('ml')}
        isActive={selectedCategory === 'ml'}
      />
    </div>
  );
};

export default Filters;